import useDdc from '@/public/src/pages/common/handlePay/hooks/useDdc.js'

// 支付宝3ds挑战
export const useAlipayCardThreeDs = ({
  paramList,
  completeHandle
}) => {
  const { initPayJsonWebToken, sendDdc } = useDdc()

  const { redirectUrl, parameters = '' } = paramList || {}
  let parametersJson = {}
  try {
    parametersJson = JSON.parse(parameters || '{}')

    if (redirectUrl && parameters) {
      let ddcResStatus = false
      initPayJsonWebToken({
        jwt: parametersJson.JWT,
        ddcReferenceId: parametersJson.ddcReferenceId,
        formActionUrl: redirectUrl,
      })
      sendDdc({
        cardBin: parametersJson.Bin || '',
        timeout: 2 * 1000,
        isAliPayCardThreeDs: true,
        callback: (data) => {
          console.log('sendCommonDDC callback', data)
          ddcResStatus = true
          completeHandle()
        }
      })
      setTimeout(() => {
        if (!ddcResStatus) {
          ddcResStatus = true
          completeHandle()
        }
      }, 2000)
    } else {
      // 未返回参数，降级直接调用回调接口
      completeHandle()
    }
  } catch (e) {
    console.log('alipay-card3ds error: ', e)
  }
}
