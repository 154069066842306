import apiReport from 'public/src/pages/common/apiReport'
import { ref } from 'vue'
import { debuggerLog } from '../utils'


export default () => {
  const jsonWebToken = ref({
    formActionUrl: '',
    jwt: '',
    loadWorldpayDdc: 0,
    wphost: '',
    ddcReferenceId: '' // 仅alipay-card3ds使用
  })
  const ddcTime = ref({
    sessionId: '',
    timer: null,
    timeout: 5 * 1000, //10秒超时
    Status: null,
    sessionTimeStart: Date.now()
  })
  const ddcSendOptions = ref({
    cardBin: '',
    callback: () => { }
  })
  const ddcResult = ref({
    sessionId: ''
  })
  const submitValid = ref(false)

  const isAliPayCard = ref(false)

  function _updateDDC({ cardBin }) {
    // 如果卡bin不是数字，则走降级
    if (!cardBin || !/^[0-9]*$/.test(`${cardBin}`)) return false
    $('#collectionForm').prop('action', jsonWebToken.value.formActionUrl)
    $(`#collectionForm input[name='Bin']`).val(cardBin)
    $(`#collectionForm input[name='JWT']`).val(jsonWebToken.value.jwt)

    if (jsonWebToken.value.ddcReferenceId?.length > 0) {
      $(`#collectionForm input[name='ddcReferenceId']`).val(
        jsonWebToken.value.ddcReferenceId
      )
    }
    return true
  }

  function _ddcSend() {
    ddcTime.value.sessionId = ''
    submitValid.value = true
    const result = _updateDDC({ cardBin: ddcSendOptions.value.cardBin })
    if (result) {
      const form = document.getElementById('collectionForm')

      if (!isAliPayCard.value) {
        //若超时，则走降级
        // 补偿请求时，如果已有定时器，需清除
        if (ddcTime.value.timer && ddcTime.value.Status !== null) {
          clearTimeout(ddcTime.value.timer)
          ddcTime.value.timer = null
        }
  
        ddcTime.value.timer = setTimeout(function () {
          if (!ddcTime.value.sessionId) {
            // window.ocpEventCenter?.$emit('ddc-submit', '')
            ddcSendOptions.value.callback?.('')
            ddcTime.value.Status = null // 重置状态，用于再次补偿
            submitValid.value = false
          }
        }, ddcTime.value.timeout)
  
        if (ddcTime.value.Status === null) {
          ddcTime.value.sessionTimeStart = Date.now()
        }
      }
      // $('#collectionForm').submit()

      // 手动触发表单提交
      form?.submit()

    } else {
      ddcSendOptions.value.callback?.('')
      // window.ocpEventCenter?.$emit('ddc-submit', '')
    }
  }

  const ddcEventHandle = event => {
    debuggerLog('event.origin', event.origin)
    if (event.origin === jsonWebToken.value.wphost) {
      if (!submitValid.value) return
      let data = undefined
      try {
        data = JSON.parse(event.data)
      } catch (e) {
        console.log(e)
      }

      if (isAliPayCard.value) {
        ddcSendOptions.value.callback?.(data)
      } else {
        clearTimeout(ddcTime.value.timer)
        if (data && typeof data === 'object') {
          if (!data.SessionId || !data.Status) {
            getSessionIdErrorReport(data)
          }
          if (data.Status) {
            getSessionIdTimeReport(data)
            ddcTime.value.sessionId = data.SessionId
            ddcResult.value.sessionId = data.SessionId
            // window.ocpEventCenter?.$emit('ddc-submit', data.SessionId)
            ddcSendOptions.value.callback?.(data.SessionId)
            ddcTime.value.Status = null // 重置状态，用于再次补偿
            submitValid.value = false
          } else {
            // 如果第一次Status为false，应补偿请求一次
            if (ddcTime.value.Status === null) {
              _ddcSend(data)
            } else {
              getSessionIdTimeReport(data)
              // window.ocpEventCenter?.$emit('ddc-submit', '')
              ddcSendOptions.value.callback?.('')
              ddcTime.value.Status = null // 重置状态，用于再次补偿
              submitValid.value = false
            }
          }
          ddcTime.value.Status = !!data.Status
        } else {
          // window.ocpEventCenter?.$emit('ddc-submit', '')
          ddcSendOptions.value.callback?.('')
          submitValid.value = false
          getSessionIdTimeReport()
          getSessionIdErrorReport()
        }
      }
    }
  }

  function _initListenerDDC() {
    window.removeEventListener('message', ddcEventHandle)
    window.addEventListener('message', ddcEventHandle)
  }

  function getSessionIdErrorReport(sessionInfo) {
    apiReport.report({
      apiPath: '/pay/formActionUrl/sessionid',
      payment_method: '',
      billno: '',
      sessionid:
        (sessionInfo && sessionInfo.SessionId) || 'error_sessionid_null',
      Status: (sessionInfo && sessionInfo.Status) || 'error_status_false'
    })
  }
  function getSessionIdTimeReport(data) {
    apiReport.report({
      apiPath: '/pay/formActionUrl/sessionidtime',
      payment_method: '',
      billno: '',
      process_time: Date.now() - ddcTime.value.sessionTimeStart,
      error_data_err_result: data?.SessionId ? 'true' : 'false'
    })
    ddcTime.value.sessionTimeStart = null
  }

  function initPayJsonWebToken(ddcInfo = {}) {
    const info = ddcInfo.value || ddcInfo || {}
    if (info.formActionUrl && info.jwt) {
      jsonWebToken.value.formActionUrl = info?.formActionUrl || ''
      jsonWebToken.value.jwt = info?.jwt || ''
      jsonWebToken.value.loadWorldpayDdc = 1

      if (info?.ddcReferenceId) {
        jsonWebToken.value.ddcReferenceId = info.ddcReferenceId
      }

      if (info?.formActionUrl) {
        try {
          const wpURL = new URL(info.formActionUrl)
          jsonWebToken.value.wphost = wpURL.protocol + '//' + wpURL.host
        } catch (e) {
          const reg =
            /((https?):\/\/)?[-A-Za-z0-9+&@#\/%?=~_|!:,.;]+\.[-A-Za-z+]+\/+/g
          const hostUrl = info.formActionUrl.match(reg)[0]
          jsonWebToken.value.wphost = hostUrl
            ? hostUrl.substring(0, hostUrl.length - 1)
            : ''
        }
      }
      debuggerLog('jsonWebToken', jsonWebToken)
      _initListenerDDC()
    }
  }

  function sendDdc({
    cardBin = '',
    timeout = 5 * 1000,
    isAliPayCardThreeDs = false,
    callback = () => { }
  }) {
    ddcSendOptions.value.cardBin = cardBin
    ddcTime.value.timeout = timeout
    isAliPayCard.value = isAliPayCardThreeDs
    ddcSendOptions.value.callback = (...args) => {
      window.removeEventListener('message', ddcEventHandle)
      return callback?.(...args)
    }
    _ddcSend()
  }

  return {
    jsonWebToken,
    initPayJsonWebToken,
    sendDdc
  }
}
